































































































































































































































































































































































































































































































import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import DetailHeader from "@/components/Layout/Backoffice/DetailHeader.vue";
import { Notification } from "@/models/notification.interface";
import { CustomDate } from "@/mixins/custom-date";
import InnerImageZoom from "vue-inner-image-zoom";

@Component({
  components: {
    Loader,
    DetailHeader,
    "inner-image-zoom": InnerImageZoom,
  },
})
export default class PaymentValidation extends mixins(Navigation, CustomDate) {
  loader = false;
  dialogPaymentDetail = false;
  paymentPreview: any = {};

  approvedDialog = false;
  unapprovedDialog = false;
  paymentValidationDialog = false;

  loading = false;

  private async created(): Promise<void> {
    this.loader = true;

    await this.$store
      .dispatch("bill/getBill", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.pv-e35") + this.$route.params.id,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    this.setNavigation({
      previousRoute: "/staff/investments",
      icon: "mdi-clipboard-text",
      title: `${this.$tc("Views.sn-1")}: ${this.$route.params.id}`,
    });
    this.loader = false;
  }

  private get bill() {
    return this.$store.getters["bill/getBill"];
  }

  private openApprovedDialog(payment: any) {
    this.paymentPreview = payment;
    this.approvedDialog = true;
  }

  private openUnapprovedDialog(payment: any) {
    this.paymentPreview = payment;
    this.unapprovedDialog = true;
  }

  private closeApprovedDialog() {
    this.paymentPreview = {};
    this.approvedDialog = false;
  }

  private closeUnapprovedDialog() {
    this.paymentPreview = {};
    this.unapprovedDialog = false;
  }

  private checkPreviewType(previewType: string): boolean {
    let type = previewType.toLowerCase();

    if (
      type == ".jpeg" ||
      type == ".png" ||
      type == ".jpg" ||
      type == ".svg" ||
      type == ".webm"
    ) {
      return true;
    } else {
      return false;
    }
  }

  private async approvePayment(): Promise<void> {
    let updateFunction;
    this.loading = true;

    if (
      this.paymentPreview.platform.type ==
      this.$constants.PLATFORM_TYPE.TRADITIONAL
    ) {
      updateFunction = "updateStatusForTraditionalPayment";
    } else {
      updateFunction = "updateStatusForCryptoPayment";
    }

    await this.$store
      .dispatch(`payment/${updateFunction}`, {
        id: this.paymentPreview.id,
        status: this.$constants.STATUS.FINISHED,
      })
      .then(async () => {
        await this.$store
          .dispatch("bill/getBill", this.$route.params.id)
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("Views.pv-e35") + this.$route.params.id,
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });

        const Success: Notification = {
          message: this.$tc("Views.pv-s36"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);

        if (this.bill.paid >= this.bill.amount || !this.checkPaymentsStatus()) {
          this.openPaymentValidationDialog();
        }
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.pv-e37") + this.paymentPreview.id,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.paymentPreview = {};
        this.approvedDialog = false;
        this.loading = false;
      });
  }

  private async unapprovePayment(): Promise<void> {
    let updateFunction;
    this.loading = true;

    if (
      this.paymentPreview.platform.type ==
      this.$constants.PLATFORM_TYPE.TRADITIONAL
    ) {
      updateFunction = "updateStatusForTraditionalPayment";
    } else {
      updateFunction = "updateStatusForCryptoPayment";
    }

    await this.$store
      .dispatch(`payment/${updateFunction}`, {
        id: this.paymentPreview.id,
        status: this.$constants.STATUS.CANCELED,
      })
      .then(async () => {
        await this.$store
          .dispatch("bill/getBill", this.$route.params.id)
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("Views.pv-e35") + this.$route.params.id,
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });

        const Success: Notification = {
          message: this.$tc("Views.pv-s38"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);

        if (this.bill.paid >= this.bill.amount || !this.checkPaymentsStatus()) {
          this.openPaymentValidationDialog();
        }
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.pv-e37") + this.paymentPreview.id,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.paymentPreview = {};
        this.unapprovedDialog = false;
        this.loading = false;
      });
  }

  private goToSalesPage() {
    this.navigate("/staff/investments");
  }

  private openPaymentValidationDialog() {
    this.paymentValidationDialog = true;
  }

  private checkPaymentsStatus(): boolean {
    let response = false;

    this.bill.payments_cryptocurrency.forEach((payment) => {
      if (payment.status.name == this.$constants.STATUS.VALIDATING) {
        response = true;
      }
    });

    this.bill.payments_traditional.forEach((payment) => {
      if (payment.status.name == this.$constants.STATUS.VALIDATING) {
        response = true;
      }
    });

    return response;
  }
}
